/* #root {} */
#toolbarRow {
  height: 42px;
  display: flex;
  align-items: center;
  margin-top: 1px;
  width: 100%;
}
#spacer {
    flex-grow: 1;
}